import React from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import CloseIcon from "./images/close-icon.svg";
import BestRate from "./images/best-rates.png";
import Wallet from "./images/wallet.svg";
import Button from "../../shared/button";
import { FINANCE_TNC_LINK } from "../../../constants/app-constants";
import { trackAmplitude, trackDesktopCustomEventsAU } from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const OurRateMatchPromisePopup = ({ setIsRateMatchPopupVisible, isRateMatchPopupVisible }) => {

    const onModalClose = () => {
        setIsRateMatchPopupVisible(false);
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.INTEREST_RATE_MATCH, {
            eventAction: "Rate_match_promise",
            eventLabel: "Closed"
        });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.INTEREST_RATE_MATCH, {
            "Rate_match_promise": "Closed"
        });
    };

    React.useEffect(() => {
        if (isRateMatchPopupVisible) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.INTEREST_RATE_MATCH, {
                eventAction: "Rate_match_promise",
                eventLabel: "Opened"
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.INTEREST_RATE_MATCH, {
                "Rate_match_promise": "Opened"
            });
        }
    }, [isRateMatchPopupVisible]);

    return (
        <Modal isOpen={true}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.headerWrapper"}>
                    <p styleName={"styles.heading"}>Our rate match promise</p>
                    <img src={CloseIcon} alt="Close Popup" onClick={onModalClose}/>
                </div>
                <div styleName={"styles.blueBoxWrapper"}>
                    <div styleName={"styles.leftWrapper"}>
                        <img src={BestRate} alt="Best Rate Icon" />
                        <p styleName={"styles.getBestInterest"}>Get best interest rates,<br />with CARS24 finance</p>
                    </div>
                    <img src={Wallet} alt="Wallet Icon" />
                </div>
                <p styleName={"styles.disclaimer"}>If you find a lower rate, we'll match it as well as contribute <span>$300 towards your deposit.<br /></span> <a target="_blank" href={FINANCE_TNC_LINK}>T&C apply</a><br /><br />Reach out at <a styleName={"styles.email"} href="mailto:financing.au@cars24.com">financing.au@cars24.com</a></p>
                <Button text="OK, GOT IT" onClick={onModalClose} />
            </div>
        </Modal>
    );
};

OurRateMatchPromisePopup.propTypes = {
    setIsRateMatchPopupVisible: PropTypes.func,
    isRateMatchPopupVisible: PropTypes.bool
};
export default OurRateMatchPromisePopup;
