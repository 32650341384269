import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import BackgroungBanner from "./images/ff-background.png";
import Button from "../../shared/button";
import styles from "./styles.css";
import { trackDesktopCustomEventsAU, trackAmplitude } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { AMPLITUDE_EVENTS_CATEGORY} from "../../../tracking/amplitude-events";
import { getPreApprovedAmount, getffFooterText } from "../../../utils/helpers/get-pre-approved-amount";
import PercentIcon from "./images/percentage.svg";
import OurRateMatchPromisePopup from "../our-rate-match-promise-popup/component";
import makePriceLabel from "../../../utils/helpers/make-price-label";
import getUpdatedAppliedFilters from "../../../utils/filters-v2/get-updated-applied-filters";
import Filters from "../../../utils/filters-v2";

const GetPreApprovedCardVariantB = ({ maxPermissibleRepaymentPerWeek, firstName, clearAllFiltersConnect, absolutePermissibleLoanAmount, preApprovalFirstName, bestInterestRateCalculatorDetails, updateFilterOptionDataConnect, updateSelectedSuggestionsConnect, reloadCarListConnect, allFilters}) => {
    const history = useHistory();
    const [isRateMatchPopupVisible, setIsRateMatchPopupVisible] = React.useState(false);

    const {numberOfVehiclesSupported} = bestInterestRateCalculatorDetails || {};

    const handleRangeFilter = () => {
        const filterKey = "quotedPrice";
        const rangeValues = { min: 0, max: absolutePermissibleLoanAmount };
        const displayText = `Under ${makePriceLabel(absolutePermissibleLoanAmount)}`;

        const filterData = {
            filterKey,
            isSelected: true,
            isRangeFilter: true,
            rangeValues,
            isSingleEntity: true,
            isSuggestion: true,
            displayText
        };

        updateFilterOptionDataConnect({ filterKey, isSelected: true, isRangeFilter: true, rangeValues, displayText});
        updateSelectedSuggestionsConnect(filterData);
        const currentFilters = new Filters({ cityCode: "" });

        const updatedFilter = getUpdatedAppliedFilters(filterData, []);
        const {relativeURL: listingUrl} = currentFilters.getListingPayload({ selectedFilters: updatedFilter, filters: allFilters});
        history.push(listingUrl);
        reloadCarListConnect(true);

    };

    const handleBrowseCars = () => {
        if (absolutePermissibleLoanAmount) {
            handleRangeFilter();
        } else {
            clearAllFiltersConnect();
            history.push("/buy-used-cars-australia/");
        }

    };

    const handleFinanceKnowMore = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.NUDGES_FINANCE_KNOW_MORE_FINANCE, {
            eventLabel: "Home_page"
        });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.KNOW_MORE_FINANCE, {
            "Know_more": "Home_page"
        });
        history.push("/cars24-finance");
    };

    const handleBestInterestClick = () => {
        setIsRateMatchPopupVisible(true);
    };

    return (
        <React.Fragment>
            <div styleName={"styles.preapprovedWrapper"}>
                <img src={BackgroungBanner} alt="ff preapproved banner"/>
                <div styleName={"styles.detailsWrapper"}>
                    <p styleName={"styles.textStyle1"}>Congratulations! {preApprovalFirstName || firstName || ""}</p>
                    <p styleName={"styles.textStyle2"}>You’ve been pre-approved for finance</p>
                    <p styleName={"styles.textStyle3"}>upto {getPreApprovedAmount(absolutePermissibleLoanAmount, maxPermissibleRepaymentPerWeek)}</p>
                    <div styleName={"styles.perksWrapper"}>
                        <div styleName={"styles.perksInnerWrapper"}>
                            <img src={PercentIcon} alt="percent icon" />
                            <button styleName={"styles.bestInterestsText"} onClick={handleBestInterestClick}>{"Best interest rates>>"}</button>
                        </div>
                    </div>
                    <Button text={absolutePermissibleLoanAmount ? `View ${Number(numberOfVehiclesSupported).toLocaleString("en-AU")} eligible cars` : "BROWSE CARS"} styleName={"styles.buttonStyle"} onClick={handleBrowseCars}/>
                    <p styleName={"styles.knowMore"} onClick={handleFinanceKnowMore}>Know more about finance </p>
                </div>
                <div styleName={"styles.flexRow"}>
                    <p styleName={"styles.textStyle4"}>{getffFooterText(absolutePermissibleLoanAmount, maxPermissibleRepaymentPerWeek)}</p>
                </div>

            </div>
            {isRateMatchPopupVisible && <OurRateMatchPromisePopup setIsRateMatchPopupVisible={setIsRateMatchPopupVisible} isRateMatchPopupVisible={isRateMatchPopupVisible} />}
        </React.Fragment>
    );
};

GetPreApprovedCardVariantB.propTypes = {
    maxPermissibleRepaymentPerWeek: PropTypes.number,
    firstName: PropTypes.string,
    clearAllFiltersConnect: PropTypes.func,
    absolutePermissibleLoanAmount: PropTypes.number,
    preApprovalFirstName: PropTypes.string,
    updateFilterOptionDataConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    updateSelectedSuggestionsConnect: PropTypes.func,
    allFilters: PropTypes.object,
    bestInterestRateCalculatorDetails: PropTypes.object

};

export default GetPreApprovedCardVariantB;
